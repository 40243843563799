<template>
  <label :class="hasMargin ? 'mb-2' : 'mb-0'">
    <p v-if="description" class="mb-4 mt-0">
      {{ description }}
    </p>

    <template v-if="type === 'file'">
      <div class="text-sm left-4 font-semibold">
        {{ label }} {{ required ? "" : "(optional)" }}
      </div>
      <FileUpload
        description="Format must be JPG/HEIC/PNG/TIFF/PDF"
        accept=".jpg,.heic,.jpeg,.png,.tiff,.pdf"
        :name="name"
        :disabled="disabled"
        :required="required"
        @update="onInput"
      />
    </template>

    <div v-else class="relative flex h-12">
      <span
        class="transition-[top] text-md absolute left-4 text-gray-text"
        :class="
          type === 'date' || focused || modelValue ? 'top-1 text-xs' : 'top-4'
        "
      >
        {{ label }} {{ required ? "" : "(optional)" }}
      </span>
      <Input
        :id="inputId"
        ref="inputElement"
        class="pt-4.5 pl-4 h-full text-onyx-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        :model-value="modelValue"
        :disabled="disabled"
        :name="name"
        :invalid="!!error"
        :required="required"
        :type="type"
        :pattern="pattern"
        :autocomplete="autocomplete"
        :color="inputColor"
        :tabindex="inputTabindex"
        :size="inputSize"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />
    </div>

    <div v-if="error" class="text-red-600 mt-1 text-sm">{{ error }}</div>
  </label>
</template>

<script lang="ts" setup>
  import { ref } from "vue"
  import Input from "./Input/index.vue"
  import FileUpload from "./FileUpload/index.vue"
  import { inputValidation } from "~/assets/js/inputValidation"

  interface Props {
    label?: string
    type?: "text" | "email" | "password" | "file" | "number"
    name?: string
    autocomplete?: string
    disabled?: boolean
    invalid?: boolean
    modelValue?: string
    required?: boolean
    hasMargin?: boolean
    minlength?: number
    maxlength?: number
    pattern?: string
    inputId?: string
    inputColor?: "gray" | "white"
    inputTabindex?: string
    inputSize?: "medium" | "small" | "large"
    description?: string
    messageSpace?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    label: "",
    name: "",
    disabled: false,
    type: "text",
    autocomplete: undefined,
    invalid: false,
    hasMargin: true,
    messageSpace: false,
    modelValue: "",
    required: false,
    minlength: undefined,
    maxlength: undefined,
    pattern: undefined,
    inputId: undefined,
    inputColor: "gray",
    inputTabindex: undefined,
    inputSize: undefined,
    description: undefined,
  })

  const inputElement = ref<InstanceType<typeof Input> | null>(null)
  const error = ref<string | null>(null)
  const focused = ref(false)

  const onFocus = () => {
    focused.value = true
  }

  const emit = defineEmits(["update:modelValue", "blur"])

  const textInput = (e: Event) => {
    const value = (e.target as HTMLInputElement).value
    error.value = inputValidation({
      value,
      label: props.label,
      minlength: props.minlength,
      maxlength: props.maxlength,
      pattern: props.pattern,
      required: props.required,
    })
  }

  const onInput = (e: Event) => {
    const target = e.target as HTMLInputElement
    switch (props.type) {
      case "file":
        emit("update:modelValue", e)
        break
      default:
        emit("update:modelValue", target.value)
        textInput(e)
        break
    }
  }

  const onBlur = (e: Event) => {
    focused.value = false
    onInput(e)
  }

  onMounted(() => {
    if (process.env.NODE_ENV === "development" && props.messageSpace) {
      console.warn(
        "Forminput.vue: messageSpace is deprecated. Please use hasMargin instead.",
      )
    }
  })
</script>

<template>
  <label class="file-input relative inline-flex flex-col justify-center gap-4">
    {{ description }}
    <input
      ref="fileUpload"
      :accept="accept"
      :disabled="disabled"
      :required="required"
      type="file"
      class="block w-full text-sm text-slate-500 file:border-gray-200 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-solid file:text-sm file:font-semibold file:bg-gray-tint file:gray-text"
      @change="getFiles"
    />
  </label>
</template>

<script>
  export default {
    props: {
      description: {
        type: String,
        default: null,
      },
      accept: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        files: [],
      }
    },
    methods: {
      getFiles(e) {
        this.files = this.$refs.fileUpload.files
        this.$emit("change", e)
        this.$emit("update", this.files)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .file-input {
    // Not supported by tailwindcss
    text-align-last: left;
  }
</style>

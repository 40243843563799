interface ValidationOptions {
  value: string
  label: string
  minlength?: number
  maxlength?: number
  pattern?: string
  required?: boolean
}

export const inputValidation = (options: ValidationOptions): string => {
  if (!options.value && !options.required) {
    return ""
  }
  if (options.required && !options.value) {
    return `${options.label} is required`
  }
  if (options.minlength && options.value.length < options.minlength) {
    return `${options.label} must be at least ${options.minlength} characters long`
  }
  if (options.maxlength && options.value.length > options.maxlength) {
    return `${options.label} should not be longer than ${options.maxlength} characters`
  }
  if (options.pattern && !RegExp(options.pattern).test(options.value)) {
    return `${options.label} does not match the expected format`
  }
  return ""
}
